<template>
  <div>
    <el-dialog
      title="Create Event"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="closeModal"
      :close-on-press-escape="false"
    >
      <!-- Select Calendar -->
      <el-row :gutter="20">
        <el-col :span="12">
          <el-select
            v-model="selectedCalendar"
            filterable
            clearable
            placeholder="Select Calendar"
            style="width: 100%"
            size="mini"
          >
            <el-option
              v-for="calendar in calendars"
              :key="calendar.id"
              :label="calendar.name"
              :value="calendar.id"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="12">
          <el-select
            v-model="eventForm.timeZone"
            filterable
            clearable
            placeholder="Select Timezone"
            style="width: 100%"
            size="mini"
          >
            <el-option
              v-for="tz in timezoneList"
              :key="tz.value"
              :label="tz.label"
              :value="tz.value"
            />
          </el-select>
        </el-col>
      </el-row>

      <!-- Event Title -->
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :span="24">
          <el-input
            v-model="eventForm.title"
            placeholder="Event Title"
            size="mini"
          ></el-input>
        </el-col>
      </el-row>
      <!-- Attendees Section -->
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :span="24">
          <div class="attendees-group" @click="focusInput('attendeeInput')">
            <!-- Display Attendees as Tags -->
            <el-tag
              v-for="(email, index) in eventForm.attendees"
              :key="'attendee-' + index"
              closable
              @close="removeAttendee(index)"
              type="success"
              size="mini"
              style="margin-right: 5px; cursor: pointer"
              @keydown.backspace="removeAttendee(index)"
              tabindex="0"
            >
              {{ email }}
            </el-tag>

            <!-- Editable Span for Typing -->
            <span
              ref="attendeeInput"
              contenteditable="true"
              class="editable-span"
              @keydown.enter.prevent="addAttendee"
              @blur="addAttendee"
            ></span>
          </div>
          <p v-if="attendeeError" class="error-message">{{ attendeeError }}</p>
        </el-col>
      </el-row>

      <!-- Optional Attendees Checkbox -->
      <el-row :gutter="20" style="margin-top: 10px">
        <el-col :span="24">
          <el-checkbox v-model="includeOptionalAttendees">
            Add Optional Attendees
          </el-checkbox>
        </el-col>
      </el-row>

      <!-- Optional Attendees Section -->
      <el-row
        v-if="includeOptionalAttendees"
        :gutter="20"
        style="margin-top: 10px"
      >
        <el-col :span="24">
          <div
            class="attendees-group"
            @click="focusInput('optionalAttendeeInput')"
          >
            <!-- Display Optional Attendees as Tags -->
            <el-tag
              v-for="(email, index) in eventForm.optionalAttendees"
              :key="'optional-' + index"
              closable
              @close="removeOptionalAttendee(index)"
              type="success"
              style="margin-right: 5px; cursor: pointer"
              @keydown.backspace="removeOptionalAttendee(index)"
              tabindex="0"
            >
              {{ email }}
            </el-tag>

            <!-- Editable Span for Typing -->
            <span
              ref="optionalAttendeeInput"
              contenteditable="true"
              class="editable-span"
              @keydown.enter.prevent="addOptionalAttendee"
              @blur="addOptionalAttendee"
            ></span>
          </div>
          <p v-if="optionalAttendeeError" class="error-message">
            {{ optionalAttendeeError }}
          </p>
        </el-col>
      </el-row>

      <!-- Event Description -->
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :span="24">
          <div
            v-loading="ckeditorLoading"
            :element-loading-text="loadingText"
            style="margin-top: 10px"
            size="mini"
          >
            <ckeditor
              v-if="isOpened"
              v-model="eventForm.description"
              :config="editorConfig"
              :key="editorKey"
            ></ckeditor>
          </div>
        </el-col>
      </el-row>

      <!-- Start Date & Time && End Date Time && Reminder-->
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :span="8">
          <el-date-picker
            v-model="eventForm.startDateTime"
            type="datetime"
            placeholder="Start Date & Time"
            style="width: 100%"
            size="mini"
          />
        </el-col>

        <!-- End Date & Time -->
        <el-col :span="8">
          <el-date-picker
            v-model="eventForm.endDateTime"
            type="datetime"
            placeholder="End Date & Time"
            style="width: 100%"
            size="mini"
          />
        </el-col>

        <el-col :span="8">
          <el-select
            v-model="eventForm.reminder"
            placeholder="Reminder"
            style="width: 100%"
            size="mini"
          >
            <el-option
              v-for="time in reminderOptions"
              :key="time.value"
              :label="time.label"
              :value="time.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>

      <!-- Recurrence Options -->
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :span="10">
          <el-select
            v-model="recurrence.type"
            placeholder="Select recurrence"
            style="width: 100%"
            size="mini"
          >
            <el-option label="None" value="none"></el-option>
            <el-option label="Daily" value="daily"></el-option>
            <el-option label="Weekly" value="weekly"></el-option>
            <el-option label="Monthly" value="monthly"></el-option>
            <el-option label="Yearly" value="yearly"></el-option>
            <!-- <el-option label="Custom" value="custom"></el-option> -->
          </el-select>
        </el-col>
        <!--End Date-->
        <el-col :span="10" v-if="recurrence.type !== 'none'">
          <el-date-picker
            v-model="recurrence.endDate"
            type="date"
            placeholder="Select end date"
            style="width: 100%"
            :disabled="recurrence.noEnd"
          />
        </el-col>
        <el-col :span="4" v-if="recurrence.type !== 'none'">
          <el-checkbox v-model="recurrence.noEnd" @change="handleNoEndChange">
            No End
          </el-checkbox>
        </el-col>
      </el-row>

      <!-- Custom Recurrence Options -->
      <el-row
        v-if="recurrence.type === 'custom'"
        :gutter="20"
        style="margin-top: 20px"
      >
        <el-col :span="12">
          <el-select
            v-model="recurrence.unit"
            placeholder="Select unit"
            style="width: 100%"
            size="mini"
          >
            <el-option label="Days" value="day"></el-option>
            <el-option label="Weeks" value="week"></el-option>
            <el-option label="Months" value="month"></el-option>
            <el-option label="Years" value="year"></el-option>
          </el-select>
        </el-col>
      </el-row>

      <!-- Days of the Week (if weekly) -->
      <el-row
        v-if="
          recurrence.type === 'weekly' ||
          (recurrence.type === 'custom' && recurrence.unit === 'week')
        "
        :gutter="20"
        style="margin-top: 20px"
      >
        <el-col :span="24">
          <el-checkbox-group v-model="recurrence.weekDays">
            <el-checkbox label="Monday" value="mon"></el-checkbox>
            <el-checkbox label="Tuesday" value="tue"></el-checkbox>
            <el-checkbox label="Wednesday" value="wed"></el-checkbox>
            <el-checkbox label="Thursday" value="thu"></el-checkbox>
            <el-checkbox label="Friday" value="fri"></el-checkbox>
            <el-checkbox label="Saturday" value="sat"></el-checkbox>
            <el-checkbox label="Sunday" value="sun"></el-checkbox>
          </el-checkbox-group>
        </el-col>
      </el-row>

      <!-- Monthly Day Selection -->
      <el-row
        v-if="recurrence.type === 'monthly'"
        :gutter="20"
        style="margin-top: 20px"
      >
        <el-col :span="12">
          <el-radio-group v-model="recurrence.monthlyType">
            <el-radio label="day">On a Specific Day</el-radio>
            <el-radio label="weekday">On a Specific Weekday</el-radio>
          </el-radio-group>
        </el-col>

        <!-- If user chooses a specific day -->
        <el-col
          :span="12"
          v-if="
            recurrence.type === 'monthly' && recurrence.monthlyType === 'day'
          "
        >
          <el-input-number
            v-model="recurrence.dayOfMonth"
            :min="1"
            :max="31"
            label="Day of Month"
          />
        </el-col>

        <!-- If user chooses a specific weekday -->
        <el-col
          :span="6"
          v-if="
            recurrence.type === 'monthly' &&
            recurrence.monthlyType === 'weekday'
          "
        >
          <el-select v-model="recurrence.weekNumber" placeholder="Select Week">
            <el-option label="First" value="first"></el-option>
            <el-option label="Second" value="second"></el-option>
            <el-option label="Third" value="third"></el-option>
            <el-option label="Fourth" value="fourth"></el-option>
            <el-option label="Last" value="last"></el-option>
          </el-select>
        </el-col>

        <el-col
          :span="6"
          v-if="
            recurrence.type === 'monthly' &&
            recurrence.monthlyType === 'weekday'
          "
        >
          <el-select
            v-model="recurrence.weekDays"
            placeholder="Select Day"
            multiple
            clearable
            filterable
            collapse-tags
            collapse-tags-tooltip
          >
            <el-option label="Monday" value="Monday"></el-option>
            <el-option label="Tuesday" value="Tuesday"></el-option>
            <el-option label="Wednesday" value="Wednesday"></el-option>
            <el-option label="Thursday" value="Thursday"></el-option>
            <el-option label="Friday" value="Friday"></el-option>
            <el-option label="Saturday" value="Saturday"></el-option>
            <el-option label="Sunday" value="Sunday"></el-option>
          </el-select>
        </el-col>
      </el-row>

      <!-- If user chooses a yearly-->
      <el-row
        v-if="recurrence.type === 'yearly'"
        :gutter="20"
        style="margin-top: 20px"
      >
        <el-col :span="12">
          <el-radio-group v-model="recurrence.yearlyType">
            <el-radio label="date">On a Specific Date</el-radio>
            <el-radio label="weekday">On a Specific Weekday</el-radio>
          </el-radio-group>
        </el-col>

        <!-- If user chooses a specific date -->
        <el-col
          :span="12"
          v-if="
            recurrence.type === 'yearly' && recurrence.yearlyType === 'date'
          "
        >
          <el-date-picker
            v-model="recurrence.yearlyDate"
            type="date"
            placeholder="Select Date"
            style="width: 100%"
            size="mini"
          />
        </el-col>
      </el-row>

      <!-- If user chooses a specific weekday -->
      <el-row
        v-if="
          recurrence.type === 'yearly' && recurrence.yearlyType === 'weekday'
        "
        :gutter="20"
        style="margin-top: 20px"
      >
        <el-col :span="8">
          <el-select
            v-model="recurrence.yearlyMonth"
            placeholder="Select Month"
          >
            <el-option label="January" value="1"></el-option>
            <el-option label="February" value="2"></el-option>
            <el-option label="March" value="3"></el-option>
            <el-option label="April" value="4"></el-option>
            <el-option label="May" value="5"></el-option>
            <el-option label="June" value="6"></el-option>
            <el-option label="July" value="7"></el-option>
            <el-option label="August" value="8"></el-option>
            <el-option label="September" value="9"></el-option>
            <el-option label="October" value="10"></el-option>
            <el-option label="November" value="11"></el-option>
            <el-option label="December" value="12"></el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select
            v-model="recurrence.yearlyWeekNumber"
            placeholder="Select Week"
          >
            <el-option label="First" value="first"></el-option>
            <el-option label="Second" value="second"></el-option>
            <el-option label="Third" value="third"></el-option>
            <el-option label="Fourth" value="fourth"></el-option>
            <el-option label="Last" value="last"></el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select
            v-model="recurrence.yearlyWeekDays"
            placeholder="Select Day"
            multiple
            clearble
            filterable
            collapse-tags
            collapse-tags-tooltip
          >
            <el-option label="Monday" value="Monday"></el-option>
            <el-option label="Tuesday" value="Tuesday"></el-option>
            <el-option label="Wednesday" value="Wednesday"></el-option>
            <el-option label="Thursday" value="Thursday"></el-option>
            <el-option label="Friday" value="Friday"></el-option>
            <el-option label="Saturday" value="Saturday"></el-option>
            <el-option label="Sunday" value="Sunday"></el-option>
          </el-select>
        </el-col>
      </el-row>

      <!-- Footer Buttons -->
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: flex-end; width: 100%"
      >
        <el-button @click="closeModal" size="mini">Cancel</el-button>
        <el-button type="primary" @click="createEvent" size="mini"
          >Create</el-button
        >
      </span>
    </el-dialog>

    <el-button type="primary" @click="dialogVisible = true"
      >Create Event</el-button
    >
  </div>
</template>

<script>
import { ckEditorConfig } from "@/assets/data/ckeditor_config.ts";

// import VueTimezoneSelect from "vue-timezone-select";

import {
  getAccounts,
  getConfigurations,
  getCalendarList,
  createCalendarEvent,
} from "../../components/user-integrations/KF-mail/data/messages";
import moment from "moment-timezone";
import { fetchEntityById } from "@/repo/entityRepo";
import { postAPICall } from "@/helpers/httpHelper";

export default {
  data() {
    return {
      dialogVisible: true,
      editorConfig: JSON.parse(JSON.stringify(ckEditorConfig)),
      editorKey: 0,
      selectedCalendar: "",
      calendars: [],
      eventForm: {
        title: "",
        description: "",
        startDateTime: "",
        endDateTime: "",
        timeZone: "UTC",
        reminder: "15",
        attendees: [],
        optionalAttendees: [],
      },
      newAttendee: "",
      newOptionalAttendee: "",
      attendeeError: "",
      optionalAttendeeError: "",
      includeOptionalAttendees: false,
      reminderOptions: [
        { value: "5", label: "5 minutes before" },
        { value: "10", label: "10 minutes before" },
        { value: "15", label: "15 minutes before" },
        { value: "30", label: "30 minutes before" },
        { value: "60", label: "1 hour before" },
      ],
      ckeditorLoading: false, // Add this
      loadingText: "Loading editor...", // Add this
      isOpened: true, // Add this, assuming you want CKEditor to load immediately
      selectedTimezone: "", // Add this, assuming default timezone
      timezoneList: [],
      recurrence: {
        type: "none", // Recurrence type: none, daily, weekly, monthly, yearly, custom
        interval: 1, // Frequency interval (e.g., every X days/weeks/months)
        unit: "day", // Custom recurrence unit: day, week, month, year
        weekDays: [], // Days of the week for weekly recurrence
        monthlyType: "day", // "day" or "weekday"
        dayOfMonth: 1, // e.g., 15th of the month
        weekNumber: "first", // 1st, 2nd, 3rd, 4th, or last
        weekDay: "Monday", // Selected day of the week
        yearlyType: "date", // "date" or "weekday"
        yearlyDate: null, // MM-DD format (e.g., "03-10" for March 10)
        yearlyMonth: "1", // Month number (1-12)
        yearlyWeekNumber: "first", // 1st, 2nd, 3rd, 4th, last
        yearlyWeekDays: [], // Selected weekday
        endType: "date", // Either "date" or "occurrences"
        endDate: null, // Date when recurrence should end
      },
      loginUserMailConfig: null,
      companyMailConfig: null
    };
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  watch: {
    "recurrence.yearlyDate": function (newVal) {
      if (newVal) {
        const date = new Date(newVal); // Convert to Date object
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Get MM
        const day = String(date.getDate()).padStart(2, "0"); // Get DD
        this.recurrence.yearlyDate = `${month}-${day}`; // Store MM-DD
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyPress);
    this.getCalendarList();
  },
  async created() {
    try {
      this.loadTimezones();
      if (this.event.source === "entity") {
        let entityDataId = this.event.entityDataId;
        let entityId = this.event.entityId;
        let scheduledEventData = await this.getMappedEntiyData(
          entityId,
          entityDataId
        );
        this.event["scheduleEventData"] = scheduledEventData;
      }
      if (this.event && this.event.scheduleEventData) {
        if (this.event.scheduleEventData.optionalAttendees) {
          this.includeOptionalAttendees = true;
        }
        this.mapEventData(this.event.scheduleEventData);
      }
    } catch (err) {
      return err;
    }
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyPress);
  },
  methods: {
    handleNoEndChange(value) {
      if (value) {
        this.recurrence.endDate = null; // Clear end date if 'No End' is checked
        this.recurrence.endType = "noEnd";
      } else {
        this.recurrence.endType = "date"; // Default back to date if unchecked
      }
    },
    mapEventData(scheduleEventData) {
      this.eventForm = {
        title: scheduleEventData.title || "",
        description: scheduleEventData.description || "",
        startDateTime: scheduleEventData.startDateTime || "",
        endDateTime:
          scheduleEventData.endDateTime ||
          (scheduleEventData.startDateTime
            ? moment(scheduleEventData.startDateTime)
                .add(30, "minutes")
                .format("YYYY-MM-DDTHH:mm:ssZ")
            : ""),
        timeZone: this.eventForm.timeZone, // Keeping the default unless provided in scheduleEventData
        reminder: scheduleEventData.reminder
          ? scheduleEventData.reminder
          : "15", // Default, can be updated if needed
        attendees: scheduleEventData.attendees || [],
        optionalAttendees: scheduleEventData.optionalAttendees || [],
        entityId: scheduleEventData.entityId,
        entityDataId: scheduleEventData.entityDataId,
      };
    },
    loadTimezones() {
      const timezones = moment.tz.names(); // Get all timezone names
      this.timezoneList = timezones.map((tz) => {
        const offset = moment.tz(tz).utcOffset();
        const formattedOffset = `UTC${offset >= 0 ? "+" : ""}${offset / 60}`;
        return {
          value: tz,
          label: `${tz} (${formattedOffset})`,
        };
      });
    },
    //get CalendarList
    async getCalendarList() {
      try {
        this.companyMailConfig = await getAccounts();
        this.loginUserMailConfig = await getConfigurations();
        if (this.companyMailConfig?.service && this.loginUserMailConfig?._id) {
          let response = await getCalendarList(
            this.companyMailConfig?.service,
            this.loginUserMailConfig?._id
          );
          if (response && response.list) {
            this.calendars = response.list.map((calendar) => {
              return {
                id: calendar.id,
                name: calendar.name,
                primary: calendar.primary,
              };
            });
            const primaryCalendar = this.calendars.find((cal) => cal.primary);
            if (primaryCalendar) {
              this.selectedCalendar = primaryCalendar.id;
            } else if (this.calendars.length > 0) {
              this.selectedCalendar = this.calendars[0].id;
            }
          } else {
            this.calendars = [];
            this.selectedCalendar = "";
          }
        } else {
          this.$notify.error({
            title: "Error",
            message:
              "Please Configure the mail in Integrations Before Using this Functionality",
          });
        }
      } catch (err) {
        return err;
      }
    },
    // Add attendee to list
    addAttendee(event) {
      const input = event.target.innerText.trim();
      this.attendeeError = "";
      if (!this.validateEmail(input)) {
        this.attendeeError = "Invalid email format.";
        setTimeout(() => {
          this.attendeeError = "";
        }, 5000);
        return;
      }
      if (input && !this.eventForm.attendees.includes(input)) {
        this.eventForm.attendees.push(input);
      }
      event.target.innerText = ""; // Clear input field
      this.$nextTick(() => {
        event.target.focus(); // Ensure input refocuses
      });
    },
    removeAttendee(index) {
      this.eventForm.attendees.splice(index, 1);
    },
    addOptionalAttendee(event) {
      const input = event.target.innerText.trim();
      this.optionalAttendeeError = "";
      if (!this.validateEmail(input)) {
        this.optionalAttendeeError = "Invalid email format.";
        setTimeout(() => {
          this.optionalAttendeeError = "";
        }, 5000);
        return;
      }
      if (input && !this.eventForm.optionalAttendees.includes(input)) {
        this.eventForm.optionalAttendees.push(input);
      }
      event.target.innerText = ""; // Clear input field
      this.$nextTick(() => {
        event.target.focus(); // Ensure input refocuses
      });
    },
    removeOptionalAttendee(index) {
      this.eventForm.optionalAttendees.splice(index, 1);
    },
    focusInput(refName) {
      this.$refs[refName].focus();
    },

    // Validate Email Format
    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    closeModal() {
      // this.isModalOpen = false;
      this.dialogVisible = false;
      //bus emit
      this.$eventBus.$emit("hide-Calendar-Event", () => {
        this.isCalendarEventVisible = false;
      });
    },
    async createEvent() {
      const eventData = {
        calendarId: this.selectedCalendar,
        title: this.eventForm.title,
        description: this.eventForm.description,
        startDateTime: this.eventForm.startDateTime,
        endDateTime: this.eventForm.endDateTime,
        timeZone: this.eventForm.timeZone,
        reminder: this.eventForm.reminder,
        attendees: this.eventForm.attendees,
        optionalAttendees: this.eventForm.optionalAttendees,
        recurrence: this.recurrence,
        entityId: this.eventForm?.entityId,
        entityDataId: this.eventForm?.entityDataId,
      };
      try {
        this.$message.info("Creating event, please wait...");
        if (this.companyMailConfig?.service && this.loginUserMailConfig?._id) {
          let response = await createCalendarEvent(
            this.companyMailConfig?.service,
            this.loginUserMailConfig?._id,
            eventData
          );
          if (response.event.status) {
            this.$message.success(`${response.event.message}`);
            this.closeModal();
            this.resetForm();
          } else {
            this.$message.error("Failed to  create Event");
          }
        }
      } catch (error) {
        this.$message.error("Failed to create event.");
        return error;
      }
    },
    resetForm() {
      this.eventForm = {
        title: "",
        description: "",
        startDateTime: "",
        endDateTime: "",
        timeZone: "UTC",
        reminder: "15",
      };
    },
    handleKeyPress(event) {
      if (event.shiftKey && event.key === "E") {
        this.dialogVisible = true;
      }
    },
    async getMappedEntiyData(entityId, entityDataId) {
      try {
        let response = await postAPICall("GET", `/entity-data/${entityDataId}`);
        let templatesData = response.data?.templates_data;
        let currentEntity = await fetchEntityById(entityId);
        let schedulingConfiguration = currentEntity.schedulingConfiguration;
        let scheduledEventData = await this.prepareEventData(
          schedulingConfiguration,
          templatesData
        );
        scheduledEventData["entityId"] = entityId;
        scheduledEventData["entityDataId"] = entityDataId;
        return scheduledEventData;
      } catch (err) {
        return err;
      }
    },
    async prepareEventData(schedulingConfig, templatesData) {
      try {
        let eventData = {
          title: "",
          description: "",
          startDateTime: "",
          endDateTime: "",
          attendees: new Set(),
          optionalAttendees: new Set(),
          reminder: "",
        };

        // Create a map for quick access to template data by template_id
        const templateDataMap = new Map();
        templatesData.forEach((template) => {
          templateDataMap.set(
            template.template_id,
            template.template_data_id.template_data
          );
        });

        // Iterate over the scheduling configuration
        for (const [key, value] of Object.entries(schedulingConfig)) {
          if (Array.isArray(value)) {
            // If the value is an array, handle multiple fields
            value.forEach((field) => {
              const [templateId, fieldName] = field.split("#");
              const templateData = templateDataMap.get(templateId);
              if (templateData && templateData[fieldName]) {
                if (key === "attendees" || key === "optionalAttendees") {
                  if (Array.isArray(templateData[fieldName])) {
                    templateData[fieldName].forEach((email) =>
                      eventData[key].add(email)
                    );
                  } else {
                    eventData[key].add(templateData[fieldName]);
                  }
                } else {
                  eventData[key] = templateData[fieldName];
                }
              }
            });
          } else {
            // Single field mapping
            const [templateId, fieldName] = value.split("#");
            const templateData = templateDataMap.get(templateId);
            if (templateData && templateData[fieldName]) {
              eventData[key] = templateData[fieldName];
            }
          }
        }

        // Convert sets to arrays
        eventData.attendees = Array.from(eventData.attendees);
        eventData.optionalAttendees = Array.from(eventData.optionalAttendees);

        return eventData;
      } catch (err) {
        return err;
      }
    },
  },
};
</script>

<style scoped>
.attendees-group {
  border: 1px solid #dcdfe6;
  padding: 5px;
  min-height: 35px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  cursor: text;
}

.editable-span {
  min-width: 50px;
  padding: 5px;
  outline: none;
  border: none;
  display: inline-block;
  white-space: nowrap;
}
.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
</style>
