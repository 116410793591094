<template>
  <div
    v-if="isModalOpen"
    :class="['compose-modal', { 'modal-minimized': isMinimized }]"
  >
    <el-card class="modal-header" :body-style="{ padding: '8px 10px' }">
      <div class="header-content">
        <span class="modal-title">New Message</span>
        <div class="header-actions">
          <el-button
            class="minimize-btn"
            size="mini"
            @click="toggleMinimize"
            v-if="!isMinimized"
            >_</el-button
          >
          <el-button
            class="maximize-btn"
            size="mini"
            @click="toggleMaximize"
            v-if="!isMaximized"
            >□</el-button
          >
          <el-button
            class="restore-btn"
            size="mini"
            @click="restoreModal"
            v-if="isMinimized || isMaximized"
            >❐</el-button
          >
          <el-button
            class="close-btn"
            size="mini"
            @click="closeModal"
            icon="el-icon-close"
          ></el-button>
        </div>
      </div>
    </el-card>

    <el-card
      v-show="!isMinimized"
      class="modal-body"
      :body-style="{ padding: '15px' }"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-input
            v-model="emailData.toInput"
            placeholder="To (add with comma seperated)"
            @blur="parseEmails('to')"
            class="input-field"
            :class="{ 'input-error': errors.to }"
            clearable
          />
        </el-col>
        <el-col :span="24">
          <el-input
            v-model="emailData.ccInput"
            placeholder="Cc (add with comma seperated)"
            @blur="parseEmails('cc')"
            class="input-field"
            clearable
          />
        </el-col>
        <el-col :span="24">
          <el-input
            v-model="emailData.bccInput"
            placeholder="Bcc (add with comma seperated)"
            @blur="parseEmails('bcc')"
            class="input-field"
            clearable
          />
        </el-col>
        <el-col :span="24" class="subject-field">
          <el-input
            v-model="emailData.subject"
            placeholder="Subject"
            class="subject-field"
          />
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <ckeditor
            v-model="editorData"
            :editor="editor"
            :config="editorConfig"
            class="drawer-editor"
          />
        </el-col>
      </el-row>

      <!-- Attachment Upload -->
      <el-row>
        <el-col :span="24">
          <el-upload
            class="upload-demo"
            :action="uploadAction"
            :on-change="handleFileChange"
            :on-remove="handleRemoveFile"
            :auto-upload="false"
            :file-list="emailData.attachments"
            list-type="text"
            multiple
            accept="*/*"
          >
            <el-button size="small" type="primary">Attach Files</el-button>
          </el-upload>
        </el-col>
      </el-row>
    </el-card>

    <el-card class="modal-footer" :body-style="{ padding: '8px 10px' }">
      <el-button class="send-btn" type="primary" @click="submitForm">
        Send
      </el-button>
      <el-button class="save-draft-btn" @click="saveDraft">
        Save Draft
      </el-button>
    </el-card>
  </div>
</template>
<script>
import { postAPICall } from "@/helpers/httpHelper";
import { mapGetters } from "vuex";
import Config from "../../config/app";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      companyMailConfig: {},
      loginUserMailConfig: {},
      isModalOpen: true,
      isMinimized: false,
      isMaximized: false,
      emailData: {
        to: [],
        cc: [],
        bcc: [],
        subject: "",
        message: "",
        attachments: [],
        toInput: "",
        ccInput: "",
        bccInput: "",
      },
      errors: {
        to: false,
        subject: false,
      },
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "|",
          "alignment",
          "link",
          "|",
          "imageUpload",
          "insertImage",
          "blockQuote",
          "insertTable",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "undo",
          "redo",
        ],
      },
      editorData: "",
      uploadAction: "/upload/endpoint", // Your upload endpoint here
    };
  },
  computed: {
    ...mapGetters("auth", ["getActiveWorkspace", "getAuthenticatedUser"]),
  },
  props: {
    email: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.getAccounts();
    this.getConfigurations();
  },
  created() {
    if (this.email?.email) {
      this.activeSubTab = this.email.email;
      if (!this.emailData.to.includes(this.email.email)) {
        this.emailData.to.push(this.email.email);
        this.emailData.toInput.length > 0
          ? (this.emailData.toInput += "," + this.email.email)
          : (this.emailData.toInput = this.email.email + ",");
      }
    }
  },
  // destroyed() {
  //   console.log("ComposeMail destroyed");
  // },
  methods: {
    closeModal() {
      this.isModalOpen = false;

      //bus emit
      this.$eventBus.$emit("hide-mail-compose", () => {
        this.isMailComposeVisible = false;
      });
    },
    parseEmails(field) {
      let inputValue;
      if (field === "to") inputValue = this.emailData.toInput;
      else if (field === "cc") inputValue = this.emailData.ccInput;
      else if (field === "bcc") inputValue = this.emailData.bccInput;

      if (inputValue) {
        const emails = inputValue
          .split(",")
          .map((email) => email.trim())
          .filter((email) => email !== "");
        if (field === "to") this.emailData.to = emails;
        else if (field === "cc") this.emailData.cc = emails;
        else if (field === "bcc") this.emailData.bcc = emails;
      }
    },
    handleFileChange(file, fileList) {
      // Add file to attachments
      this.emailData.attachments = fileList.map((item) => ({
        name: item.name,
        size: item.size,
        type: item.type,
        raw: item.raw,
      }));
    },
    handleRemoveFile(file, fileList) {
      // Remove the file from attachments
      this.emailData.attachments = fileList.map((item) => ({
        name: item.name,
        size: item.size,
        type: item.type,
        raw: item.raw,
      }));
    },
    toggleMinimize() {
      this.isMinimized = true;
      this.isMaximized = false;
    },
    toggleMaximize() {
      this.isMaximized = true;
      this.isMinimized = false;
      // Additional logic for maximizing the modal
    },
    restoreModal() {
      this.isMinimized = false;
      this.isMaximized = false;
      // Additional logic for restoring the modal to default size
    },
    async getAccounts() {
      this.loading = true;
      this.loadingText = "Loading configurations ...";
      try {
        let response = await postAPICall("GET", "/user_integrations/list/MAIL");
        if (response && response.success) {
          if (response.data._id) {
            const decrypted = this.$CryptoJS.AES.decrypt(
              response.data.clientSecret,
              "MAIL"
            );
            const info = decrypted.toString(this.$CryptoJS.enc.Utf8);
            this.companyMailConfig = JSON.parse(info);

            this.companyMailConfig = {
              ...this.companyMailConfig,
              ...response.data,
            };
          } else {
            this.$message.error(
              "Configurations data not matched with your Owner!"
            );
            this.loading = false;
          }
        }
      } catch (error) {
        this.$message.error("Invalid Configuration!");
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async getConfigurations() {
      this.loading = true;
      this.loadingText = "Loading User configurations ...";
      try {
        let response = await postAPICall("GET", "/user_integrations/user/MAIL");
        if (response && response.success && response.data.length) {
          this.loginUserMailConfig =
            response.data && response.data.length ? response.data[0] : {};
          const decrypted = this.$CryptoJS.AES.decrypt(
            this.loginUserMailConfig.clientSecret,
            "MAIL"
          );
          const info = decrypted.toString(this.$CryptoJS.enc.Utf8);
          this.loginUserMailConfig = {
            ...this.loginUserMailConfig,
            ...JSON.parse(info),
          };
        }
      } catch (error) {
        this.$message.error("Invalid Configuration!");
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    validateForm() {
      // Reset errors
      this.errors.to = false;
      this.errors.subject = false;

      // Validate "To" and "Subject" fields
      if (!this.emailData.to) {
        this.errors.to = true;
      }
      if (!this.emailData.subject) {
        this.errors.subject = true;
      }

      // Return whether the form is valid
      return !this.errors.to && !this.errors.subject;
    },
    async submitForm() {
      try {
        // Validate fields
        if (!this.validateForm()) {
          alert("Please fill in the required fields.");
          return;
        }
        const formData = new FormData();
        // Collect all data including the files
        formData.append("to", JSON.stringify(this.emailData.to));
        formData.append("cc", JSON.stringify(this.emailData.cc));
        formData.append("bcc", JSON.stringify(this.emailData.bcc));
        formData.append("subject", this.emailData.subject);
        formData.append("message", this.editorData || "");

        // Append multiple files
        if (
          this.emailData.attachments &&
          this.emailData.attachments.length > 0
        ) {
          this.emailData.attachments.forEach((file) => {
            formData.append(`attachments`, file.raw, file.name);
          });
        }
        if (this.loginUserMailConfig && this.companyMailConfig) {
          const response = await postAPICall(
            "POST",
            `${Config.KF_EMAIL}/sendmail/${this.companyMailConfig.service}/${this.loginUserMailConfig._id}`,
            formData
          );
          if (response.email.status) {
            this.$message({
              message: "Mail Sent Succeessfully",
              type: "success",
            });
          } else {
            this.$message({
              message: "failed to send Mail",
              type: "error",
            });
          }
        } else {
          console.log("elseeeeeeee");
        }
        this.closeModal();
      } catch (error) {
        if (error.response) {
          this.$message({
            message: error.response.data.message,
            type: "error",
          });
        } else {
          console.error("Error:", error.message);
        }
      }
    },
    saveDraft() {
      alert("Save draft functionality is not implemented.");
    },
  },
};
</script>

<style scoped>
.compose-modal {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 600px;
  max-height: 85vh;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 10px; /* Rounded corners for modern look */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.modal-minimized {
  width: 300px;
  height: 40px;
  overflow: hidden;
}
.modal-minimized .modal-header {
  background-color: #1a73e8; /* Background color when minimized */
  padding: 2px;
  /* Other styles */
}
.input-error .el-input__inner {
  border-color: red;
}

.modal-title {
  font-weight: 500;
  font-size: 18px;
  flex: 1;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-actions {
  display: flex;
}
.modal-body {
  flex: 1;
  overflow-y: auto; /* Enable scrolling for the body */
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.minimize-btn,
.maximize-btn,
.restore-btn,
.close-btn {
  margin-left: 5px;
  padding: 8px;
  cursor: pointer;
}

.modal-header {
  background-color: #f9f9f9;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.input-field .el-input__inner {
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 12px 0;
  font-size: 14px;
  /* box-sizing: border-box; */
  background-color: transparent;
}

.input-field .el-input__inner:focus {
  border: none;
  border-bottom: 0px solid #1a73e8; /* Gmail blue */
  outline: none;
}

.input-field .el-input__inner::placeholder {
  color: #5f6368; /* Gray placeholder */
  font-size: 14px;
}

.input-field .el-input__inner:disabled {
  background-color: #f1f3f4;
}

.subject-field {
  margin-bottom: 5px;
  margin-top: 3px;
  /* border: none;
  border-bottom: 1px solid gray;
  padding: 12px 0;
  font-size: 14px; */
  /*background-color: transparent; 
} 

.recipient-input:focus,
.subject-input:focus {
  border-bottom: 2px solid #a7abb1; /* Highlight bottom border on focus */
}

.input-field .el-input__inner::-webkit-input-placeholder {
  color: #5f6368;
}

::v-deep(.ck-editor__editable) {
  height: 200px;
  overflow-y: auto;
}
.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #f9f9f9;
}

.send-btn,
.save-draft-btn {
  width: 120px;
  margin-right: 10px;
  padding: 10px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  background-color: #1a73e8; /* Gmail blue */
  color: #fff;
  border: none;
  cursor: pointer;
}

.send-btn:hover,
.save-draft-btn:hover {
  background-color: #1765a2;
}

.upload-demo {
  margin-top: 10px;
}

.upload-demo .el-upload__input {
  padding: 3px 10px;
}

.upload-demo .el-upload__list {
  margin-top: 5px;
}

.upload-demo .el-upload__list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ck-editor__editable_inline {
  border: 1px solid #ccc;
  min-height: 150px;
  padding: 10px;
}

.ck-editor__editable_inline:focus {
  border-color: #1a73e8;
}

.upload-demo .el-upload__list-item .el-upload__remove {
  font-size: 14px;
}
</style>
