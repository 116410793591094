import Vue from "vue";
import moment from "moment";
import store from "@/store";

Vue.filter("getUserFullName", (user) => {
  if (!user) return "";
  let fullName = user.first_name ? user.first_name : "";
  fullName += user.first_name && user.last_name ? " " : "";
  fullName += user.last_name ? user.last_name : "";
  return fullName;
});

Vue.filter("getMenuTitle", (menuName) => {
  let menuList =
    store.getters["menuManagementV2/getMenu"] &&
    store.getters["menuManagementV2/getMenu"]["menu_list"]
      ? store.getters["menuManagementV2/getMenu"]["menu_list"]
      : [];
  let currentMenuItem = store.getters["navigationOpen/getCurrentMenu"];
  if (menuList && menuList.length && currentMenuItem && currentMenuItem.title) {
    return currentMenuItem.title;
  }
  return menuName;
});

Vue.filter("getUserFullNameShortened", (user) => {
  if (!user) return "";
  let name = "";
  name += user.first_name && user.first_name.length ? user.first_name[0] : "";
  if (name.length > 0) {
    name +=
      user.last_name && user.last_name.length ? ". " + user.last_name[0] : "";
  } else {
    name += user.last_name && user.last_name.length ? user.last_name[0] : "";
  }
  return name.toUpperCase();
});

Vue.filter("getUserNameAvatar", (user) => {
  if (!user) return "";
  let name = "";
  name += user.first_name && user.first_name.length ? user.first_name[0] : "";
  name += user.last_name && user.last_name.length ? user.last_name[0] : "";
  return name.toUpperCase();
});
Vue.filter("globalDateFormat", (date) => {
  if (!date) return "";
  let compSettings = store.getters["company/getCompanySettings"];
  let format = store.getters["auth/getDefaultDateFormat"]
    ? store.getters["auth/getDefaultDateFormat"]
    : "MM-DD-YYYY";
  if (compSettings?.priority == "company" && compSettings.date) {
    format = compSettings.date;
  }
  return moment(date, moment.ISO_8601, true).isValid()
    ? moment(date).format(format)
    : date;
});

Vue.filter("globalDateTimeFormat", (date) => {
  if (!date) return "";
  let compSettings = store.getters["company/getCompanySettings"];
  let defaultDateFormat =
    store.getters["auth/getDefaultDateFormat"] || "MM-DD-YYYY";
  if (compSettings?.priority == "company" && compSettings.date) {
    defaultDateFormat = compSettings.date;
  }
  let timeFormat = store.getters["auth/getDefaultTimeFormat"] || 24;
  if (compSettings?.priority == "company" && compSettings.time) {
    timeFormat = compSettings.time;
  }
  const timeFormatString = timeFormat === 12 ? "hh:mm:ss A" : "HH:mm:ss";
  const format = `${defaultDateFormat} ${timeFormatString}`;
  return moment(date, moment.ISO_8601, true).isValid()
    ? moment(date).format(format)
    : date;
});

Vue.filter("globalTimeFormat", (time) => {
  if (!time) return "";
  let compSettings = store.getters["company/getCompanySettings"];
  let formatHours = store.getters["auth/getDefaultTimeFormat"];
  if (compSettings?.priority == "company" && compSettings.time) {
    formatHours = compSettings.time;
  }
  const parseTime = (timeStr) => {
    let [hours, minutes, seconds] = timeStr.split(":").map(Number);
    if (!seconds) seconds = '00';
    let suffix = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; 
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")} ${suffix}`;
    };
    if (typeof time === "string" && time.includes(":")) {
      if (formatHours == 12) {
        return parseTime(time);
      } else {
        return time; 
      }
    } 
    else {
      const format = formatHours == 12 ? "hh:mm:ss A" : "HH:mm:ss";
      return moment(time).format(format);
    }
});

Vue.filter("getFormattedDate", (dateStr) => {
  // 2021-12-03
  var dt = new Date(dateStr);
  var today = new Date();
  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  var rtn = dt.toLocaleDateString("en-US", options);
  var todayStr = today.toLocaleDateString("en-US", options);
  if (rtn == todayStr) {
    rtn = "Today - " + rtn;
  }
  return rtn;
});

Vue.filter("getFormattedDateTypeThree", (dateStr) => {
  if (!dateStr) return "";

  function getFormattedDateTypeTwo(dateStr2) {
    // 2021-12-03T09:41:13.163Z -> 03-12-2021 09:30 AM
    // 2021-10-18T17:19:31.823Z 19-10-20 2::9: AM
    var dt = new Date(dateStr2);

    var dateStrLocal = dt.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }); // -> 02/28/2004
    var timeStrLocal = dt.toLocaleTimeString("en-GB"); // -> 23:45:26
    // console.log('>>> getFormattedDateTypeTwo(), dateStrLocal, timeStrLocal', dateStrLocal, timeStrLocal);
    // var rtn = dateStrLocal.substring(3, 5) + "-" + dateStrLocal.substring(0, 2) + "-" + dateStrLocal.substring(6, 10) + " ";
    const format = store.getters["auth/getDefaultDateFormat"]
      ? store.getters["auth/getDefaultDateFormat"]
      : "MM-DD-YYYY";
    var rtn = moment(dateStrLocal).format(format);

    var hourStr = timeStrLocal.substring(0, 2);
    var amPm = "AM";
    var hour = parseInt(hourStr);
    if (hour > 12) {
      var hourTmp = hour - 12;
      hourStr = (hourTmp < 10 ? "0" : "") + hourTmp.toString();
      amPm = "PM";
    }
    rtn = rtn + " " + hourStr + ":" + timeStrLocal.substring(3, 5) + " " + amPm;
    return rtn;
  }
  // console.log('>>> getFormattedDateTypeThree(), dateStr', dateStr);
  // 2021-12-03T09:41:13.163Z -> 03-12-2021 09:30 AM, Just Now, 1 hour ago, Today, Yesterday,
  var dt = new Date(dateStr);
  var today = new Date();

  // Reset time parts to compare only dates
  var dtDateOnly = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
  var todayDateOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());

  var timeDiff = today - dt; // Difference in milliseconds
  var hoursDiff = Math.floor(timeDiff / (3600 * 1000));
  var minsDiff = Math.floor(timeDiff / (60 * 1000));

  var rtn = "";
  var dateStrTypeTwo = getFormattedDateTypeTwo(dateStr);

  if (dtDateOnly.getTime() === todayDateOnly.getTime()) {
    // Check if the date is today
    if (minsDiff < 5) {
      rtn = "Just Now";
    }
    else if (minsDiff < 60) {
      rtn = minsDiff.toString() + (minsDiff == 1 ? " min" : " mins") + " ago";
    } else if (hoursDiff < 12) {
      rtn = hoursDiff.toString() + (hoursDiff == 1 ? " hour" : " hours") + " ago";
    }
    else{
      rtn = "Today ";
    }
  } else if (dtDateOnly.getTime() === todayDateOnly.getTime() - 86400000) {
    rtn = "Yesterday " + dateStrTypeTwo.split(" ")[1] + " " +dateStrTypeTwo.split(" ")[2];
  } else {
    rtn = dateStrTypeTwo;
  }
  // console.log('>>> getFormattedDateTypeThree(), rtn', rtn);
  return rtn;
});

var filter = function (text, length, clamp) {
  clamp = clamp || "...";
  var node = document.createElement("div");
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter("truncate", filter);

Vue.filter("getUserRoleShortened", (role) => {
  if (!role) return "";
  let name = "";
  let words = role.split(" ");
  words.forEach((element) => {
    name += element[0].toUpperCase();
  });
  return name.toUpperCase();
});

Vue.filter("toCurrency", function (value, currencyType) {
  if (typeof value !== "number") {
    return value;
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyType,
  }).format(value);
});

Vue.filter("toDateTimeRange", (date) => {
  if (!date) return "";
  let compSettings = store.getters["company/getCompanySettings"];
  let defaultDateFormat =
    store.getters["auth/getDefaultDateFormat"] || "MM-DD-YYYY";
  if (compSettings?.priority == "company" && compSettings.date) {
    defaultDateFormat = compSettings.date;
  }
  let timeFormat = store.getters["auth/getDefaultTimeFormat"] || 24;
  if (compSettings?.priority == "company" && compSettings.time) {
    timeFormat = compSettings.time;
  }
  const timeFormatString = timeFormat === 12 ? "hh:mm A" : "HH:mm";
  const format = `${defaultDateFormat} ${timeFormatString}`;
  if (Array.isArray(date)) {
    return `${moment(date[0]).format(format)} TO ${moment(date[1]).format(
      format
    )}`;
  } else {
    return date;
  }
});
Vue.filter("toDateRange", (date) => {
  if (!date) return "";
  let compSettings = store.getters["company/getCompanySettings"];
  let defaultDateFormat =
    store.getters["auth/getDefaultDateFormat"] || "MM-DD-YYYY";
  if (compSettings?.priority == "company" && compSettings.date) {
    defaultDateFormat = compSettings.date;
  }

  // console.log("format date range",date,moment(date[0]).format('MM-DD-YYYY HH:MM:SS'),moment(date[1]).format('MM-DD-YYYY HH:MM:SS'))

  if (Array.isArray(date)) {
    return `${moment(date[0]).format(defaultDateFormat)} (From ~ To) ${moment(
      date[1]
    ).format(defaultDateFormat)}`;
  } else {
    // return `${moment(date[0]).format('MM-DD-YYYY')} TO ${moment(date[1]).format('MM-DD-YYYY')}`
    return moment(date).format(defaultDateFormat);
  }
});
Vue.filter("toTimeRange", (time) => {
  if (!time) {
    return "";
  }
  let compSettings = store.getters["company/getCompanySettings"];
  let timeFormat = store.getters["auth/getDefaultTimeFormat"] || 24;
  if (compSettings?.priority == "company" && compSettings.time) {
    timeFormat = compSettings.time;
  }
  const timeFormatString = timeFormat === 12 ? "hh:mm A" : "HH:mm";
  if (Array.isArray(time)) {
    return `${moment(time[0]).format(timeFormatString)} - ${moment(
      time[1]
    ).format(timeFormatString)}`;
  } else {
    return time;
  }
});
Vue.filter("toWeekDays", (weekdays) => {
  if (!weekdays) {
    return "";
  }
  if (Array.isArray(weekdays)) {
    return weekdays.join(",");
  } else {
    return weekdays;
  }
});
Vue.filter("toMultiSelect", (multi) => {
  if (!multi) {
    return "";
  }
  if (Array.isArray(multi)) {
    return multi.join(",");
  } else {
    return multi;
  }
});

Vue.filter("truncateHTML", (text, maxLength) => {
  var tempElement = document.createElement("div");
  tempElement.innerHTML = text;
  var textContent = tempElement.textContent || tempElement.innerText || "";
  var truncatedText = textContent.slice(0, maxLength);
  if (textContent.length > maxLength) {
    truncatedText += "...";
  }
  return truncatedText;
});

/**
 * Convert seconds to HH:MM:SS
 * If seconds exceeds 24 hours, hours will be greater than 24 (30:05:10)
 *
 * @param {number} seconds
 * @returns {string}
 */
// const secondsToHms = (seconds) => {
//   const SECONDS_PER_DAY = 86400;
//   const HOURS_PER_DAY = 24;
//   const days = Math.floor(seconds / SECONDS_PER_DAY);
//   const remainderSeconds = seconds % SECONDS_PER_DAY;
//   const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
//   return hms.replace(/^(\d+)/, h => `${Number(h) + days * HOURS_PER_DAY}`.padStart(2, '0'));
// };

Vue.filter("toTimeFormat", function (value) {
  return value;
  // we don't need to convert to time

  // if (typeof value == "number") {
  //   return value;
  // }
  // let finalValue = ""
  // if (value < 0) {
  //   value = Math.abs(value)
  //   finalValue = "- "
  // }

  // switch (timeType) {
  //   case "Time":
  //     finalValue += secondsToHms(value)
  //     break;
  //   case "HH":
  //     finalValue += (value / (60 * 60)).toFixed(1) + " Hrs";
  //     break;
  //   case "MM":
  //     finalValue += (value / 60).toFixed(1) + " Mins";
  //     break;
  //   case "SS":
  //     finalValue += value + " Secs";
  //     break;
  // }
  // return finalValue
});


Vue.filter("truncateHTML", function (text, maxLength) {
  var tempElement = document.createElement("div");
  tempElement.innerHTML = text;
  var textContent = tempElement.textContent || tempElement.innerText || "";
  var truncatedText = textContent.slice(0, maxLength);
  return textContent.length > maxLength ? truncatedText + "..." : truncatedText;
});

