import io from "socket.io-client";
import store from "@/store";

let apiUrl =
  process.env.VUE_APP_STAGE == "DEV"
    ? process.env.VUE_APP_DEV_API_URL
    : process.env.VUE_APP_LOCAL_API_URL;
let schedulersUrl = process.env.VUE_APP_CRON_API_URL;

let companyDetails =
  store.getters["company/getCompanyDetails"] ||
  store.getters["company/getContactLoginSlug"];

let headers = {
  transports: ["websocket"],
  reconnection: true,
  reconnectionAttempts: 3,
  reconnectionDelay: 3000,
  extraHeaders: {
    workspaceid: companyDetails?._id || "",
  },
};

apiUrl = apiUrl.replace("/v1.0", "");

const socket1 = io(apiUrl, headers);
const socket2 = io(schedulersUrl,headers);

export default {
  install(Vue) {
    Vue.prototype.$socket1 = socket1;
    Vue.prototype.$socket2 = socket2;
  },
};